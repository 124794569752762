.Lab_homeConatiner {
    background-color: #F5F8F7;
    height: 81vh;
    width: 98%;
    overflow: scroll;

}

.Lab_report_hightlight {
    background-color: #FFF4F3
}

/* .lab_home_main_header {
    height: 80vh;
    width: 98%;
} */
.labhome_table_container {
    flex-direction: row;
    display: flex;
    flex: 1;
    margin-top: 0vw;
    height: 28vh;

}

/* .lab_home_main_header {
    margin-top: 1vw;
} */

.Lab_subcontainer {
    margin: 1vw
}

.Lab_home_subHeader {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* justify-content: flex-end; */
}

.Lab_home_emed_homeheader {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -1.5vh;
}

.Lab_home_emed_mainContainer {
    display: flex;
    justify-content: flex-end;
}


.Lab_home_subContainers {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.Lab_home_subContainer2 {
    flex-direction: row;
    display: flex;
    margin-top: 0.5vw;
    justify-content: space-between;
    height: 7vh;
}

.Lab_home_headerStyle {
    font-size: 1vw;
}

#Lab_home_textInput {
    margin-bottom: 0;
}

.Lab_home_dateOfBirth {
    height: 2.5vh;
    width: 10vw;
    margin-right: 0.5vw;
}

.Lab_SavedBill_date {
    height: 2.5vh;
    width: 11vw;
    margin-right: 1vw;
}

.PercentageSymbol {

    font-weight: 300;
    font-size: 0.5vw;
    display: flex;
    justify-content: center;
}

.deteofBirthContainer {
    display: flex;
    flex-direction: row;
}

.Lab_home_invoice_date {
    height: 2vh;
    width: 7.5vw;
    margin-left: 0.6vw;
}

.Lab_home_main {
    margin: -0.3vw;
}

.Lab_Home_Add {
    flex: 0.18;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 52vh;
    margin-top: 1vw;
}

.Lab_Home_Table {
    flex: 0.82;
    background-color: white;
}

.Lab_Home_Table_Header {
    background-color: #FAF9F9;
    height: 1.4vh;
    width: 100%;
}

.Lab_Home_Table_body {
    background-color: white;
    display: flex;
    flex: 1;
    overflow: scroll;
    width: 100%;
}

.Lab_Status_Table_body {
    background-color: #FFFFFF;
    display: flex;
    flex: 1;
    /* overflow: scroll; */
    width: 100%;

}

.Lab_Home_Table_Container {
    border-radius: 0.3vw;
    height: 28vh;

}

.Lab_Home_Table_Container_status2 {
    border-radius: 0.3vw;
    height: 51vh;
    display: flex;
    flex: 0.45;
    margin-left: 1vw;
    background-color: white;
    flex-direction: column;
    border-width: 1 px;
    border-color: lightgray;


}

.Lab_Home_Table_Container_status {
    border-radius: 0.3vw;
    height: 39vh;
    display: flex;
    flex: 0.6;
    background-color: #F5F8F7;
}

.Lab_home_Radio {
    display: flex;
    flex-direction: row;
}

.Lab_home_Radio_Main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4vh;
    margin-bottom: 0.5vw;
}

.Lab_Cancel_Bill_Radio {
    display: flex;
    flex-direction: row;
    flex: 1;
    border: 1px solid lightgray;
    height: 20vh;
    width: 56.5vw;
    justify-content: center;
    align-items: center;
    border-radius: 0.3vw;
}

.Lab_Cancel_Popup {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1vw;
    justify-content: space-between;
    width: 55vw;

}

.Lab_home_Radio_Text {
    font-weight: 500;
    font-size: 1vw;
    flex: 0.18;
}

.Lab_Home_Dialogboxwidth {
    padding: 15px;
    width: 30vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Lab_Status_Radio_Text {
    font-weight: 500;
    font-size: 1vw;
    flex: 0.18;
    margin-left: 4vw;
}

.Lab_Home_Radio_Button {
    flex: 0.82;
    font-size: small;
    align-self: center;
    display: flex;
    flex-direction: row;
}

.Lab_Home_Radio_Button1 {
    flex: 0.82;
    font-size: small;
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-left: 0.8vw;
}

#lab_home_table_Head {
    background-color: #FAF9F9;
    font-weight: 600;
    color: #616161;
}

.Lab_Home_Payment_container {
    border-width: 1;
    border-color: #FFFFFF;
    border-radius: 0.3vw;
    background-color: white;
    flex: 0.6;
    display: flex;
    flex-direction: column;
    padding: 0.5vw;
}

.Lab_Home_Radio_Split {
    display: flex;
    flex: 1;
    margin: 0.5vw;
}

.Lab_Radio_First_Container {
    flex: 0.18;
}

.Lab_home_Second_Container {
    flex: 0.22;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Lab_home_amount_view {
    display: flex;
    justify-content: end;
}

.Lab_Home_Payment_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5vw;
}

.Lab_Home_Text {
    display: flex;
    align-items: center;
}

.Lab_Home_payment_text {
    flex-direction: row;
    display: flex;
    justify-content: end;
    flex: 1;
}

.Lab_home_discount_per {
    flex: 1;
    display: flex;
    padding: 0vw;
    justify-content: space-between;

}

.discountTextField {
    display: flex;
    flex: 0.6;
    flex-direction: row;
    justify-content: space-between;
    /* margin-left: -1vw; */
}

.Lab_home_buttom_view {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.8vw;
    margin-left: 0.5vw;
}

.Lab_home_pop_buttom_view {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.8vw;
    margin-left: 0.5vw;
    width: 15vw;
}

.Lab_Home_Saved_Bill {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 0.8vw;
    background-color: #F5F8F7;
    border-radius: 0.2vw;
    padding: 0.4vw;
    justify-content: center;
    align-items: center;
}

.savedBillText {
    font-weight: 400;
    font-size: 1vw;
}

.savedTextLight {
    color: lightgray;
    font-size: 0.6vw;
    margin-left: 1vw;
}

.Lab_saved_bill_sub_container {
    display: flex;
    flex-direction: row;

}

.Lab_Status_Container {
    background-color: #F5F8F7;
    height: 80vh;

}

.Lab_Status_Table_Containers {
    border-radius: 0.3vw;
    height: 65vh;
    z-index: 0;
    margin-top: 0.5vw;
}

.emed_DialogboxOrder {
    margin-top: 70px;
}

.Lab_Saved_Bill_Main {
    width: 25.5vw;
    height: 30vh;
    overflow: scroll;
}

#eMed_dropDown_Lab_home {
    width: 9.5vw
}

#eMed_dropDown_Lab_home_Title {
    width: 7vw;
    border-radius: 0.5vw;

}

#eMed_dropDown_Lab_status {
    width: 8vw;
    height: 4vh;
    margin-right: 2vw;
}

.sample_collect_popup {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    background-color: #F5F8F7;
    height: 6vh;
    align-items: center;
    padding: 0.5vw
}

.sample_specimen_collect_popup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F5F8F7;
    height: 3vh;
    align-items: center;
    padding: 1.2vw
}

.specimen_collect_tab {
    padding: 10px !important;
}

.MuiTabs-indicator {
    background-color: rgb(4, 183, 177) !important;
}

.Lab_status_Speciman_container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.Lab_status_table_container {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: 1vw;
    justify-content: space-between;
    height: 60vh;

}

.Lab_status_speciman_bottom {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 2vh;
}

.Lab_Status_Header1 {
    display: 'flex';
    flex: 0.6;
    margin: 0.2vw
}

.Lab_Status_split {
    display: 'flex';
    flex: 0.05;
    margin: 0.1vw
}

.Lab_Status_Value {
    display: flex;
    flex: 0.4;
    margin: 0.1vw
}

.TableButtonCel {
    margin-top: 0;

}

.Lab_status_Button {
    display: flex;
    flex-direction: row;
}

.Lab_Status_Close_Button {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
}

.lab_print {
    height: 4.5vh;
    width: 3vw;
    margin-top: 0vw;
    align-self: center;
    align-items: center;
}

.headerValue {
    color: #616161;
    font-size: 0.87vw;
    align-self: center;
    margin: 0.5vw
}

.headerStyle {
    font-size: 1vw;
    color: #202020;
}

.Lab_status_Consumable {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
}

.consumablesField {
    height: 4vh;
    margin-top: 0.5vw;
}

.Lab_Home_Table_Sub_Container {
    display: flex;
    flex-direction: row;
    flex: 0.6;
    border-width: 1px;
    border-color: lightgray;
    height: 23vh;
}

.consumable_style {
    display: flex;
    flex: 1;
}

.ConsumableTableContainer {
    min-height: 66vh;
}

.consumable_button {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 1vw;
}

.Lab_status_sample_bottom {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin: 1vw;
}

.Lab_Home_No_record {
    height: 22vh;
}

.Lab_Saved_Bill_Button {
    flex: 0.1;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 4vh;
    margin-top: 0vw;
}

.Lab_saved_Bill_Text {
    color: white;
}

.SavedBillFirst {
    display: flex;
    flex: 0.65;
    flex-direction: column;
}

.SavedBillSecond {
    display: flex;
    flex: 0.25;
    flex-direction: column;
}

.Saved_bill_date {
    z-index: 100;
    position: absolute;
    background-color: white;
    margin: 2vw;

}

.datePicker {
    height: 40vh;
    width: 4vw;
}

.Lab_Status_Default_Consumables {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.Lab_Status_Tables_Main {
    display: flex;
    flex: 0.55;
    flex-direction: column;
    height: 70vh;
}

.Lab_Status_Tables_Con {
    height: 34vh;
}

.Lab_Previous_Report_Con {
    height: 45vh;
    overflow: scroll;
    margin-top: 1vw;
}

.Lab_Status_Tables_Con1 {
    height: 30vh;
    margin-top: 1vw;
    border-width: 1px;
    width: 100%;
    border-color: #616161;
}

.Lab_Home_DeleteIcon {
    width: 1.3vw;
    height: 1.3vw;
}

.Lab_Home_Delete {
    width: 1vw;
    height: 0.9vw;
}

.lab_status_sub_header {
    display: flex;
    flex: 1;
    flex-direction: row;
    /* justify-content: flex-end; */
    margin-right: 1vw;
}

.lab_status_printBtn {
    border-radius: 0.2vw;
    background-color: #ffff;
    border: 1px solid #66686A;
    margin-top: 1vw;
    margin-right: 1vw;
    justify-content: center;
    height: 5vh;
}

.Lab_Status_Subcontainer_Img {
    width: 3vw;
    height: 3vw;
}

.Lab_status_multi_option_button {
    margin-left: 1vw;
}

.emedhub_result_container {
    display: flex;
    justify-content: center;
}


.emedhub_result_card {
    height: 79vh;
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    margin: auto;
    margin-top: 0.7vw;
    overflow: hidden
}

.emedhub_result_top_header {
    width: 93vw;
    height: 9vw;
    margin: auto;
}

.emedhub_back_arrow {
    width: 2vw;
    height: 2vw;
    cursor: pointer;
    margin: 0.5vw;
}

.emedhub_result_header {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -3.1vw;
    left: 3.5vw;
}

.emedhub_result_header_title {
    font-size: 1vw;
}

.emedhub_result_header_text {
    font-size: 0.6vw;
}

.emedhub_Print_Button {
    height: 2.5vw;
    width: 2.5vw;
    cursor: pointer;
}

.emedhub_Print_imgBtn {
    position: relative;
    left: 80vw;
    top: -3vw;
}

.emedhub_result_table {
    border: "1px solid gray";
    height: 61vh;
    width: 90vw;
    margin: auto;
    position: relative;
    top: -4vw;
    box-shadow: 0px 0px 2px gray;
    overflow-y: scroll;
}

.emedhub_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 44vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.emedhub_popup_outsource {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 24vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.emedhub_popup_outsource_list {
    position: absolute;
    top: 20vw;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 24vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.emedhub_popup_title {
    display: flex;
    flex-direction: row;
    margin: 1vw 0vw 0vw 0.5vw;
}

.emedhub_popup_title_left {
    flex: 0.5;
    padding-left: 0.5vw;
    font-weight: 600;
    font-size: 1.5vw;
}

.emedhub_popup_title_right {
    cursor: pointer;
    height: 1vw;
    width: 1vw;
}

.emedhub_card_close_button {
    flex: 0.5;
    text-align: end;
    margin: 0.5vw 1vw 0vw 0vw;
}

.emedhub_popup_title_2 {
    display: flex;
    flex-direction: row;
    margin: 0.5vw 0vw 0vw 2vw;
}

.emedhub_popup_title_Header {
    flex: 0.5;
}

.emedhub_popup_title_dropdown {
    width: 13vw;
    position: relative;
    left: 11vw;
}

.emedhub_card {
    width: 38vw;
    height: 33.5vw;
    margin: auto;
    margin-top: 0.5vw;
    padding: 1vw;
    overflow: scroll;
    border: 2px solid lightgray;
    border-radius: 5px;
    box-shadow: 0px 0px 2px lightgray;
}

.emedhub_popup_Impression_card {
    margin-bottom: 1vw;
    box-shadow: 0px 0px 2px gray;
}

.emedhub_card_title {
    height: 2.3vw;
    max-width: 100vw;
    padding: 0.4vw 0vw 0.2vw 0.5vw;
    background-color: #F5F8F7;
}

.emedhub_card_input {
    display: flex;
    flex-direction: column;
    width: 34vw;
    margin: 1vw auto auto auto;
}

.emedhub_popup_Paragraph_card {
    box-shadow: 0px 0px 2px gray;
    margin-bottom: 1vw;
}

.emedhub_popup_Table_card {
    box-shadow: 0px 0px 2px gray;
}

.emedhub_table_input {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    width: 35vw;
    margin: auto;
}

.emedhub_table_input_card {
    width: 34vw;
    margin-top: 1vw;
    display: flex;
}

.emedhub_table_input_row {
    flex: 0.5;
}

.emedhub_table_input_column {
    flex: 0.5
}

.emedhub_popup_button {
    display: flex;
    justify-content: flex-end;
    margin: 1vw 0.75vw 0vw 0vw;
    height: 2vw
}

.emedhub_table_style {
    width: 34vw;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.emedhub_table_col {
    position: relative;
    left: 5vw;
    margin-right: 1vw;
}

.emedhub_table_row {
    margin-left: 1vw;
    margin-bottom: 1vw;
}

.table_row_header {
    display: flex;
    margin: 0.5vw;
}

.table_row {
    flex: 0.5;
    text-align: center;
    height: 1vw;
    position: relative;
    top: 0.5vw;
}


.emedhub_method_four_card {
    height: 33.5vw;
    width: 38.5vw;
    margin: auto;
    margin-top: 1vw;
    border-radius: 10px;
    box-shadow: 0px 0px 3px gray;

}

.emedhub_method_four_card_item {
    margin-top: 0.5vw;
    display: flex;
    flex-direction: column;
}

.emedhub_culture_screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: "0.75vw";
    margin-left: 1.25vw;
}

.method_four {
    margin-top: 1.5vw;
    height: 2vw;
}

.emedhub_card_method_4_header {
    width: 20vw;
    margin: 1.5vw 0.5vw 1vw 0.5vw
}

.emedhub_card_method_4_table {
    width: 75vw;
    margin-left: 1.25vw;
}

.emedhub_main_button {
    position: relative;
    top: -3.3vw;
    display: flex;
    justify-content: flex-end;
    margin-right: 1.9vw;
}

.emedhub_result_margin {
    margin: 0vw 0vw 0vw 2vw;
}

.emedhub_name_margin {
    margin: 0.5vw 0vw 0.8vw 8.5vw;
}

.emedhub_image_preview {
    border: 1px solid gray;
    height: 35vw;
    width: 38vw;
    margin: 1vw auto;
}

.emedhub_print_popup {
    border: 1px solid lightgray;
    width: 39vw;
    border-radius: 10px;
    margin: auto;
    /* height: 34vw; */
}

.emedhub_print_top_header {
    width: 39vw;
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
}

.emedhub_image_size {
    width: 38vw;
    height: 20vw;
    position: relative;
    top: 1.5vw;
    margin: auto
}

.emedhub_image_upload_button {
    position: relative;
    flex: 0.5;
    display: flex;
    padding-right: 0.5vw;
    justify-content: end;
}

.emedhub_image_resolution {
    width: 38vw;
    height: 18vw;
    object-fit: contain;
}

.emedhub_image_name {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emedhub_multiple_image_size {
    margin: auto 0.3vw;
    position: relative;
    top: 2vw;
}
.emedhub_multiple_image_size_outlist {
    margin: auto 0.3vw;
    position: relative;
}
.emedhub_lab_Result {
    display: flex;
    /* flex: 1;
    flex-direction: row; */
}

.emedhub_icon_style {
    position: relative;
    top: -8.3vw;
    left: 6.3vw;
    width: 1.3vw;
    height: 1.3vw;
}
.emedhub_icon_style_outlist {
    position: relative;
    top: -6.3vw;
    width: 1.3vw;
    height: 1.3vw;
}

.emedhub_multiple_image_resolution {
    width: 7vw;
    height: 7vw;
    margin-right: 0.5vw;
    cursor: pointer;
}

.emedhub_no_data {
    position: relative;
    top: 12vw;
    left: 40vw;
    font-size: 1.2vw;
    font-weight: 600;
    color: #616161;
}

#eMedhub_Table_Pagination {
    position: relative;
    top: 19.5vw;
    border-top: 1px solid lightgray;
}

.Lab_cancel_bill {
    height: 35vh;
    margin: 1vw;
}

.CancelBillComment {
    background-color: #F5F8F7;
    width: 50vw;
}

.Lab_Home_Receive_Bill {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: end;
}

.noRecordsStyle {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 5vw;
}

.Saved_Bill_Date {
    border: 1px solid lightgray;
    width: 10vw;
}

.emed_Dialogboxwidth_Lab_status {
    height: 73vh;
}

.Lab_Home_table_paper {
    background-color: white;
}

.MainLabHome {
    position: absolute;
    width: 94.7%;
    height: 72vh;
}

.searchCancel {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
}

.MainSubContainer {
    width: 100%;
    height: 88vh;
    margin-top: -4vw;
    margin-right: -4vw;
    background-color: rgb(0, 0, 0, 0.5);
}

.PreviousReportPopUp {
    height: 60vh;
    width: 30vw;
    padding: 1vw;
}

.Lab_Extra_Speciman {
    display: flex;
    flex-direction: row;
    flex: 1;
}

#eMed_Btn_Text {
    text-transform: capitalize;
}

.consumable_container {
    margin-left: 0.5vw;
}

.emed_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Saved_Bill_Icon {
    width: 1.5vw;
    height: 1.5vw;
    flex: 0.1;
}

.range_style {
    padding: 0.5vw;
}

.emedhub_transmit_close {
    height: 1vw;
    width: 1vw;
    cursor: pointer;
}

.test_name_style {
    display: flex;
    flex: 0.5;
    align-items: center;
    padding-left: 1vw;
}

.image_upload_button {
    width: 10vw;
    position: relative;
    top: -1vw;
}

.image_top_header {
    display: flex;
}

.table_row_input {
    flex: 0.5;
}

#table_pagination {
    position: static;
}

.lab_rajesh {
    margin-left: 12vw;
}

.labImageStyle {
    width: 2.5vw;
    height: 4.5vh;
}

.labCancelStyle {
    width: 2.5vw;
    height: 2.5vh;
}

.labDataGrid {
    height: 67vh;
    width: 92vw;
    margin: auto;
    background-color: white;
    overflow: auto;
    margin-top: 1vw;
}

.emedhub_lab_cancel_bill {
    height: 35vh;
    width: 40vw;
    margin: auto;
    background-color: white;
    overflow: scroll;
    margin-top: 1vw;
}

.eMed_sts_cls_img {
    cursor: pointer;
    height: 1vw;
    width: 1vw;
}

.eMed_sts_card {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_sts_samp_collect {
    cursor: pointer;
    height: 1vw;
    width: 1vw;
    margin-right: 1vw;
}

.eMed_tst_date_pick {
    position: relative;
    top: 0.9vw;
    /* right: 3vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1.5vw;
    width: 90vw;
}

.eMed_tst_action {
    cursor: pointer;
    margin-right: 1vw;
}

.eMed_tst_icon {
    margin-right: 2vw;
}

.eMed_sts_spc {
    margin-right: 1vw;
    cursor: pointer;
}

.eMed_sts_date {
    position: relative;
    top: 0.9vw;
    /* right: 3vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1.5vw;
    width: 90vw;
}


.eMed_create_switch {
    font-size: 0.3vw !important;
}

.eMed_status_pending {
    width: 9vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B82121;
    background-color: #FFE9E9;
    border-radius: 5px;
}

.eMed_Lab_Sts_Tbl_Two {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}

.eMed_Lab_techFlt {
    height: 12vh;
    display: flex;
    align-items: center;
    padding: 0.5vw;
}

.eMed_Lab_techTxt {
    margin: 0.5vw;
}

.eMed_Lab_techDate {
    position: relative;
    top: 0.55vw;
    width: 12vw;
}

#eMed_Lab_techFont {
    font-size: 0.9vw;
}

.eMed_Lab_Status_specimenNot {
    border: 1px solid #B8170D;
    border-radius: 3px;
    color: #B8170D;
    background: #AF4F4F30;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_specimen {
    border: 1px solid #DE7D24;
    border-radius: 3px;
    color: #DE7D24;
    background: #AF814F30;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_transfer {
    border: 1px solid #00a8f5;
    border-radius: 3px;
    color: #00a8f5;
    background: #def9ff;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_Received {
    border: 1px solid #1E972A;
    border-radius: 3px;
    color: #1E972A;
    background: #4FAF6130;
    display: flex;
    text-wrap: auto;
    align-items: center;
}

.eMed_Lab_Status_ResultUpload {
    border: 1px solid #1E972A;
    border-radius: 3px;
    color: #1E972A;
    background: #4FAF6130;
    display: flex;
    text-wrap: auto;
    align-items: center;
}