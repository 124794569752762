.eMed_Doc_Notes_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25vw;
    height: 30vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Test_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    max-height: 40vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Test_Trans_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28vw;
    height: 10vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Diagonis_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 30vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Counsellor_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 40vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_lab_master_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 42vw;
    background-color: white;
    overflow: auto;
}

.eMed_Doc_Notes_Prescrip_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    max-height: 45vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Prescrip_Fav_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28vw;
    max-height: 15vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}

.eMed_Doc_Notes_Exam_Pop_Up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    max-height: 40vw;
    background-color: white;
    overflow: auto;
    border-radius: 10px;
}
.css-1a8ioxl-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height: 55px;
}

.eMed_icon_styles{
background-color: #04B7B1;
border-radius: 0.2vw;
height: 2vw;
width: 2vw;
display: flex;
align-items: center;
justify-content: center;
margin-top: 0.2vw;
margin-right: 0.5vw;
cursor: pointer;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 13px 11.5px !important;
}

.eMed_Dosage_Type_Box{
    background-color: rgb(223, 223, 223);
    padding: 0.4vw 1vw 0.4vw 1vw;
    border:solid black 1px;
    /* border:solid #04B7B1 1px; */
    border-radius: 0.5vw;
    margin: 0.3vw 0.5vw 0.3vw 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.eMed_Dosage_Type_Box_Selectable{
    padding: 0.4vw 1vw 0.4vw 1vw;
    border:solid #04B7B1 1px;
    border-radius: 0.5vw;
    margin: 0.3vw 0.5vw 0.3vw 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.eMed_Dosage_Type_Selected_Box{
    background-color: #04B7B1;
    padding: 0 1vw 0 1vw;
    border:solid white 1px;
    border-radius: 0.5vw;
    margin: 0.3vw 0.5vw 0.3vw 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.custom-dropdown {
    position: relative;
}

.dropdown-content {
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 100;
}

.dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

.eMed_OtherDocNotes_DrawerDiv{
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
}

.eMed_OtherDocNotes_Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eMed_DocPatCate_Box {
    background-color: rgb(247, 243, 243);
    border-color: rgb(128, 128, 128);
    border-radius: 0.4vw;
    margin-bottom: 0.5vw;
    padding-right: 0.8vw;
    padding-left: 0.8vw;
    padding-top: 0.3vw;
    padding-bottom: 0.3vw;
    font-size: 0.9vw !important;
    border: 1px solid #000000;
    margin-right: 1vw;
}

.eMed_docNotess_title{
    font-size: 1vw !important;
    font-weight: 600 !important;
}

.eMed_Vitals_header_div{
    display: flex;
    justify-content: space-between;
    background-color: #efefef;
    font-weight: 600 !important;
    color: #616161;
    align-items: center;
    height: 6vh;
    border: 1px solid #000000;
    border-radius: 0.4vw;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 0.5vw;
}

.eMed_Vitals_body_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6vh;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 0.5vw;
}

.eMed_DocNotes_no_records_text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_Other_DocNotes_DrawerIcon{
    margin-left: 1vw !important;
    background-color: white !important;
    box-shadow: -3px 0.1px 0.5px 0.2vw #c1e8e1 !important;
    border-radius: 4px 0 0 4px !important;
}

.eMed_Other_DocNotes_testrgt {
    width: 100%;
    height: 50dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll
}