#emedhub_popup_mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: fit-content;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #FFFFFF;
}

#emedhub_popup_header {
    display: flex;
    justify-content: space-between;
    height: 3vw;
    padding: 0.9vw;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    outline: none;
}

#emedhub_popup_footer {
    padding: 0.625vw;
    background-color: #FAF9F9;
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
}

.emedhub_popup_footer_subdiv {
    display: flex;
    align-items: center;
    margin: -1vw;
}

.emedhub_popup_bulletin {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.emedhub_popup_footer_Text {
    font-size: 0.85vw;
    color: rgba(0, 0, 0, 0.8);
    padding: 0 0.3vw;
}

.emedhub_popup_footer_Text1 {
    font-weight: 600;
    border-right: 0.15vw solid rgba(0, 0, 0, 0.5);
}

.emedhub_popup_footer_Text2 {
    font-weight: 400;
    padding: 0 0.3vw;
}

.calenderWrap {
    display: inline-block;
    position: relative;
}

.inputBox {
    border: none;
    background-color: #FAF9F9;
    padding: 0.2vw;
    cursor: pointer;
    width: 8vw
}

.calendarElement {
    position: relative;
    box-shadow: 0px 0px 5px gray;
}

.rdrDateDisplayWrapper {
    width: 20vw !important;
}

.rdrMonthAndYearWrapper {
    width: 20vw !important;
}

.rdrMonthName {
    width: 20vw !important;
}

.rdrDays {
    width: 20vw;
    background-color: rgb(204, 204, 249);
    height: 15vw;
}

.rdrWeekDays {
    width: 20vw !important;
}

.rdrCalendarWrapper {
    width: 22vw !important;
}

.rdrMonthsHorizontal {
    width: 22vw !important;
}

.rdrStaticRangeLabel {
    color: black !important;
}

.rdrDay {
    height: 0.5vw !important;
}

.rdrStaticRangeLabel {
    padding: 0.1vw 0.5vw 0.5vw 1vw !important;
}

.rdrCalendarWrapper {
    background-color: #FAF9F9 !important;
}

.date_piker_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.eMed_DeletedPopup_Border {
    border-right: 1px solid #616161;
}

.eMed_DeletedPopup_bullet {
    width: 0.25vw;
    height: 0.25vw;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-right: 0.5vw;
    display: flex;
    align-self: center;
}

.eMed_DeletePopup_CrossIcon {
    cursor: pointer;
    width: 0.8vw;
    height: 0.8vw;
}

.eMed_Edit_btn :hover {
    transform: scale(1.2);
}
.eMed_Click_card_hover :hover {
    transform: scale(1.03);
}

.eMed_Clickable_card {
    width: 13vw;
    height: 8vw;
    display: flex;
    flex-Direction: column;
    align-items: center;
    justify-Content: center;
}

.eMed_ApoinmtCards_div {
    width: 6.65dvw;
    height: 5dvw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eMed_ApoinmtCards_count {
    padding-left: 0.3dvw;
    display: flex;
    align-items: flex-end;
    font-size: 1.4dvw;
}

.eMed_ApoinmtCards_label {
    height: 35%;
    display: "flex";
    align-items: flex-start;
    font-size: 0.8dvw;
    text-transform: capitalize;
}

.eMed_ApoinmtCards_color {
    height: 5%;
    width: 100%;
}

.eMed_Clickable_icon {
    height: 2vw;
    width: 2vw;
}

.eMed_ApoinmtCards_icon {
    height: 1.5vw;
    width: 1.5vw;
}

.eMed_ApoinmtCards_Content {
    display: flex;
    flex-direction: row;
    height: 60%;
    align-items: center;
}

.eMed_Clickable_label {
    text-transform: capitalize;
    font-size: 0.9vw;
}

.eMed_refdoc_grid {
    display: flex;
    justify-content: end;
    height: 7vh;
}

.eMed_Com_Patient_Details {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_Com_Patient_Avatar {
    display: flex;
    align-items: center;
    margin-right: 0.5vw;
}

.eMed_Com_Patient_AvaIcon {
    width: 3vw;
    height: 3vw;
    border: 2px solid;
    border-radius: 100px;
}

#eMed_Com_Patient_Name {
    font-size: 0.8vw;
    font-weight: bold;
}

#eMed_Com_Patient_UhidText {
    font-size: 0.8vw;
}
#eMed_Com_Patient_admissionTxt{
    font-size: 0.7vw;
    font-weight: 600;
}
.eMed_DialogBox_Title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5vw;
}

.eMed_DialogBox_Close {
    display: flex;
    justify-content: flex-end;
    padding: 0.5vw;
    padding-top: 0.3vw;
}

#eMed_DialogBox_Btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#eMed_Dia_Btn {
    text-transform: capitalize;
}

#emedhub_popup_content{
    max-height: 90vh;
    margin: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#emedhub_popup_container{
    width: 100%;
    padding: 1vw;
}

#popup_Patient_details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Popup_Text_Area{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    height: 4vw;
}

.popup_Appoint_details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1vw;
    margin-bottom: 0.5vw;
}

.eMed_Patient_Card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 17vw;
    padding: 0.3vw;
    margin-top: 0.5vw;
    padding-left: 0.5vw;
    margin-right: 0.5vw;
    overflow-wrap: break-word;
}

.eMed_patient_image {
    width: 2.8vw;
    height: 2.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDFFFE;
    border-radius: 0.3vw;
}

.eMed_patient_txt {
    margin-left: 0.8vw;
}

#eMed_ipsrvConfig_accTbl{
    width: 100%;
    height:47dvh;
}

.eMed_ipPackage_Div{
    height: 60vh;
}

.eMed_ipPackage_subDiv{
    padding: 1vw;
    margin-right: 0.5vw;
}

#eMed_ipPackage_rightTbl {
    height: 38vh;
    margin: -1.8vw 0.5vw 0.5vw 0.5vw;
}

#eMed_ipPackage_rightBtnCon {
    height: 9.8vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_ipPackage_txt1{
    font-size: 0.6vw;
    font-weight: 600;
}
#eMed_ipPackage_txt2{
    font-size: 0.8vw;
    font-weight: 600;
}

.eMed_PatientCard_AvaIcon {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_ImgPreviewPop_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 35vw;
    background-color: white;
    border-radius: 5px;
}

.eMed_ImgPreviewPop_img{
    min-width: 55vw;
    min-height: 30vw;
}

.eMed_ImgPreviewPop_Header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    align-items: center;
    padding: 0.5vw !important;
}
.eMed_ImgPreviewPop_Img_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31vw;
    width: 60vw;
    overflow-y: scroll;
}
.eMed_Pateint_Detail_LongData {
    flex-direction: row;
    display: flex;
    width: 19vw;
    justify-content: space-between;
}
.eMed_Pateint_Detail_LenthData {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}
.eMed_cnf_Box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_cnf_BtnGr {
    margin: 1vw;
    display: flex;
    align-items: center;
}

.eMed_Header_Search_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.eMed_App_Card{
    transition: transform 0.5s;
}

.eMed_App_Card:hover{
    transform: scale(1.1);
}

.eMed_DelCnf_msg{
    display: flex;
    flex-direction: row; 
    margin-top: 1vw;
}

.eMed_Delcnf_txt{
    font-size: 0.6vw;
    color: #616161;
    margin-right: 0.5vw;
}

.eMed_Bill_Success_Preview_Main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 18vw;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.eMed_Bill_Success_animation{
    height: 8vw;
    width: 8vw;
}

.PopupDetail{
    color : #616161;
    font-size: 0.95vw;
    width: 20vw;
    display: flex;
    flex-direction: row;
    
}

.PopupSubDetail{
    color: black;
    font-size: 0.9vw;
    font-weight: 600;
    margin-left: 0.35vw;
    margin-top: 0.05vw;
}

.eMed_branch_card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 17vw;
    padding: 0.3vw;
    margin-top: 0.5vw;
    padding-left: 0.5vw;
    margin-right: 0.5vw;
    overflow-wrap: break-word;
}