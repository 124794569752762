.Tab_batch_count {
    background-color: white(233, 99, 99);       /* Light gray background */
    border: 1px solid #dcaeae;      /* Gray border */
    border-radius: 12px;          /* Rounded corners */
    padding-top: 0.1vw;
    padding-bottom: 0.1vw;
    color: red;                 /* Gray text color */
    font-weight: bold;
    display: inline-block;
    min-width: 30px;
    text-align: center;
    font-size: 0.8vw;
}

.emed_tab_font {
    text-transform: capitalize;
    min-height: 1vw;
    font-size: 0.90vw;
    font-weight: 600;
}

.emed_approve_btn {
    height: 30px !important;
    text-transform: capitalize !important;
}