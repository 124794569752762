.eMed_Pharma_Billing_container {
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.eMed_Pharma_Header_Card {
    /* border-bottom: 1px solid rgb(203, 191, 191); */
    border-bottom: 1px solid lightgray;
    /* box-shadow: 0px 2px #e3e0e0; */
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    height: 6vh;
}

.eMed_Phrama_Table_Container{
    border: 1px solid lightgray;
    height: 35vh;
    width:92.3vw !important;
    background-color: white;
    margin: auto;
}

.eMed_Pharma_tbl_header{
    color: #616161 !important;
    font-size: 0.85vw !important;
    font-weight: 600 !important;
    background-color: #F5F8F7 !important;
}

.eMed_Pharma_del_btn{
    min-width: 0vw !important;
    height: 1.5vw !important;
    width: 1.5vw !important;
}

.eMed_Pharma_payment_card{
    height: 1.5vw;
    display: 'flex';
    align-items: center;
    position: sticky;
    background-color: #ffffff !important;
}

.eMed_Drug_Sugg_Pop{
    width:70vw;
    max-height:40vh;
}

#eMed_Pres_pop_NORec{
    text-align: center;
    position: relative;
    top: 10vh;
}
.eMed_Pres_DataView{
    width: 28.5vw;
    height: 55vh;
    overflow: scroll;
}
.eMed_App_DataView{
    width: 28.5vw;
    height: 48vh;
    overflow: scroll;
}
.eMed_Pres_Header{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5vw;
}
.eMed_Saved_DataView{
    width: 39vw;
    height: 55vh;
    overflow: scroll;
}

.eMEd_pharma_Home_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.9vw;
    padding-right: 0.9vw;
}

.eMed_pharma_header_icon_img{
    width: 1.5vw;
    height: 1.5vw;
}

.eMed_pharma_header_icon_warp {
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #EDFFFE; */
    border-radius: 0.3vw;
    margin-right: 0.5vw;
}

.eMed_Pharma_Mobile_Search_Pop{
    margin-top: 22vh;
    margin-left: 5vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
    width: 24vw;
  }

.eMed_Pharma_IPOP_Search_Pop{
    margin-top: 22vh;
    margin-left: 25.5vw;
    padding-left:1vw ;
    padding-right:1vw ;
    height: 50%;
    width: 23vw !important;
  }

  .eMed_Presc_History_pop_main{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 40vw;
    background-color: white;
    border-radius: 5px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.eMed_Presc_History_pop_table{
    width: 58vw;
    height: 25vw;
    /* margin-left: 1vw; */
    background-color: white;
}

.eMed_DrugBox_Scheduled{
    background-color: rgb(245, 231, 231) !important;
    border-color: rgb(150, 13, 11) !important;
    border-radius: 0.4vw !important;
    border-width: 1 !important;
}

.eMed_ReturnCredit_Bill_Label{
    font-size: 1vw !important;
    color: red !important;
    font-weight: 600 !important;
}

.eMed_Pharmabill_Header_Card {
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 7dvh;
}

.eMed_Pharmabill_Billing_container {
    width: 100%;
    height: 81dvh;
    margin-top: -0vw;
    background-color: #F5F8F7;
}

.eMed_Pharmabill_Table_Container {
    border: 1px solid lightgray;
    height: 34.8dvh;
    width: 92.3dvw !important;
    background-color: white;
    margin: auto;
    margin-top: 0.2dvw;
}

.eMed_Pharmabill_tbl_footer {
    display: flex;
    justify-content: space-between;
    width: 22dvw;
    margin-right: 1dvw;
}